import Image from "next/future/image";
import Link from "next/link";
import Logo from "../public/logo-white.png";

const links = [
  {
    name: "About",
    link: "/about",
  },
  { name: "Privacy Policy", link: "/privacy" },
  { name: "Contact Us", link: "/contact" },
];

const Footer = () => {
  return (
    <footer className="bg-[#222940]">
      <div className="flex items-center w-full max-w-7xl mx-auto justify-between px-4 py-6 cursor-default">
        <Link href="/">
          <span className="sr-only">classhubs</span>
          <Image src={Logo} alt="Logo" className="w-48 h-auto" />
        </Link>
        <ul className="block items-center sm:inline-flex">
          <li className="px-4">
            <Link
              href={{ pathname: "/blog/page/[page]", query: { page: 1 } }}
              className="inline-flex items-center text-gray-200 hover:text-white"
            >
              Blog
            </Link>
          </li>

          {links.map((link) => {
            return (
              <li key={link.name} className="px-4">
                <Link
                  href={link.link}
                  className="inline-flex items-center text-gray-200 hover:text-white"
                >
                  {link.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </footer>
  );
};

export { Footer };
