import Image from "next/future/image";
import Link from "next/link";
import { FunctionComponent, useEffect, useState } from "react";
import Logo from "../public/logo-black.png";

const NavBar: FunctionComponent<{
  scrollThreshold?: number;
}> = ({ scrollThreshold = 10 }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (isScrolled && window.scrollY < scrollThreshold) {
        setIsScrolled(false);
      } else if (!isScrolled && window.scrollY >= scrollThreshold) {
        setIsScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <header
      className={
        "transition duration-200 fixed right-0 top-0 left-0 z-20 bg-[#f5f6fd]" +
        (isScrolled ? " bg-white border-b-2" : "")
      }
    >
      <div className="max-w-7xl mx-auto px-4 py-6 flex items-center justify-between lg:border-none cursor-default">
        <div className="flex items-center gap-x-12">
          <Link href="/">
            <span className="sr-only">classhubs</span>
            <Image src={Logo} alt="Logo" className="w-48 h-auto" />
          </Link>
        </div>
      </div>
    </header>
  );
};

export { NavBar };
