import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Footer } from "../components/footer";
import { NavBar } from "../components/nav_bar";
import Favicon16 from "../public/favicons/favicon-16x16.png";
import Favicon32 from "../public/favicons/favicon-32x32.png";
import {
  default as AppleTouchIcon,
  default as Favicon,
} from "../public/favicons/favicon.ico";
import "../styles/globals.css";

import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

import "../components/formated_text.css";

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-NSRVZ75" });
  });

  return (
    <>
      <Head>
        <title>Find the Best Free Online Courses for You | classhubs</title>
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon32.src} />
        <link rel="icon" type="image/png" sizes="16x16" href={Favicon16.src} />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="shortcut icon" href={Favicon.src} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={AppleTouchIcon.src}
        />
      </Head>
      <div className="flex flex-col min-h-screen font-sans">
        <NavBar />
        <main className="flex-grow flex flex-col">
          <Component {...pageProps} />
        </main>
        <Footer />
      </div>
    </>
  );
}

export default MyApp;
